.InputPlayers {
  padding-top: 25px;
}

.InputPlayers p {
  font-size: 1.3rem;
}

.InputPlayers label {
  font-size: 1.2rem;
}

.slidercontainer {
  padding: 10px;
}

.slider {
  -webkit-appearance: none;
  width: 25%;
  height: 15px;
  border-radius: 5px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #202020;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #202020;
  cursor: pointer;
}

.button {
  background-color: #202020; /* Green */
  border: none;
  color: white;
  padding: 10px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  transition-duration: 0.2s;
  cursor: pointer;
  font-weight: bold;
}

.button:hover {
  color: #202020;
  background-color: white;
}

.Setup {
  padding-top: 50px;
}

.Instructions {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
