.questions-input {
  -webkit-appearance: none;
  border: none;
  background-color: #202020;
  color: white;
  padding: 10px;
  font-size: 1.5em;
  text-align: center;
  border-radius: 14px;
}

.questions-input:focus {
  -webkit-appearance: none;
  background-color: white;
  color: #202020;
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
