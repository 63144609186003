.Die {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 70px;
  height: 70px;
  background-color: white;
  /* border: 1px solid #202020; */
  border-radius: 20px;
  margin: 4px;
  font-size: 2rem;
}

.Shaking {
  animation: shake 0.42s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
