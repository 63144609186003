.button {
  background-color: #202020; /* Green */
  border: none;
  color: white;
  padding: 10px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  transition-duration: 0.2s;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

.button:hover {
  color: #202020;
  background-color: white;
}
/* 
.Dice {
  padding-top: 10px;
} */

.Dice-List {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.button:disabled {
  background-color: #202020; /* Green */

  color: white;
  cursor: not-allowed;
}

.Dice hr {
  width: 50vw;
  color: white;
  height: 5px;
  border: none;
  background-color: white;
  border-radius: 10px;
}
