body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    to right top,
    #8a81e8,
    #7e81e0,
    #7380d8,
    #6a7fcf,
    #627ec5,
    #5386c9,
    #438dcb,
    #3394cc,
    #00a6d4,
    #00b7d6,
    #00c7d3,
    #2cd6cb
  );
  height: 100vh;
  font-family: 'Baloo 2', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
