.score-box {
  display: flex;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.Highlighted {
  background-color: rgba(90, 147, 180, 0.568);
}

.left-box {
  width: 50%;
  border: 1px solid #202020;
  border-bottom: 0px;
}

.right-box {
  width: 50%;
  border: 1px solid #202020;
  border-left: 0px;
}

.bottom-box {
  border: 1px solid #202020;
  border-top: 0px;
  font-weight: bold;
}

.Scoreboard {
  margin-top: 30px;
  color: black;
  background-color: white;
  display: flex;
  justify-content: center;
}

.Scoreboard th {
  width: 80px;
}

.Scoreboard td {
  margin: 5px;
  padding: 5px;
}

.FinalScore {
  font-weight: 700;
  font-size: 1.5rem;
}
